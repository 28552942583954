import  React from 'react'
import showdown from 'showdown'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

var converter = new showdown.Converter()

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  image,
  imageCredit,
  timeToRead,
  publishedDate
}: any) => {
  const PostContent = contentComponent || Content
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div style={{
              marginBottom: '20px'
            }}>
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{ marginBottom: '10px' }}>
                {title}
              </h1>
              <div style={{
                color: 'grey',
                fontStyle: 'italic',
                paddingTop: '0px',
                paddingLeft: '5px',
                fontSize: '14px'
              }}>
                {publishedDate} - {timeToRead} min read
              </div>
            </div>
            <PreviewCompatibleImage
              imageInfo={{
                image,
                alt: `Blog Post Image`,
              }}
            />
            <div style={{ textAlign: 'center', textDecoration: 'italic' }}>
              <HTMLContent content={converter.makeHtml(imageCredit)}/>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <PostContent content={content} />
            </div>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag: any) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = ({ data }: any) => {
  const { markdownRemark: post } = data
  console.log({post})
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.summary}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{post.frontmatter.title}</title>
            <meta
              property="og:title"
              content={post.frontmatter.title}
            />
            <meta
              name="og:description"
              content={post.frontmatter.summary}
            />
            <meta
              property="og:image"
              content={`${post.frontmatter.featuredimage.childImageSharp.fluid.src}`}
            />
            <meta
              name="description"
              content={`${post.frontmatter.summary}`}
            />
            <meta
              name="keywords"
              content={`${post.frontmatter.tags.join(',')}`}
            />
          </Helmet>
        }
        timeToRead={post.timeToRead}
        publishedDate={post.frontmatter.date}
        image={post.frontmatter.featuredimage}
        imageCredit={post.frontmatter.featuredimagecredit}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        title
        summary
        tags
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredimagecredit
      }
    }
  }
`
